<template>
    <div class="sku_container">
        <div class="sku_group" v-for="(skuitem,index) in skulist_" :key="index">
            <div class="spec_title">
                <span class="label">规格名：</span>
                <el-input v-model.trim="skuitem[label]" placeholder="请输入规格名" />
                <span class="remove" @click="skulist_.splice(index, 1)">×</span>
            </div>
            <div class="group_container flex-col">
                <span class="label flex-item" style="padding-top:16px">规格值：</span>
                <div class="flex-fill">
                    <el-popover v-for="(leaf,leafindex) in skuitem[leafs]" :key="leafindex" placement="bottom" width="120" trigger="click">
                        <el-input v-model.trim="leaf[label]" placeholder="请输入规格名" style="width: 110px;" />
                        <div slot="reference" class="sku_item">
                            <div class="label">{{leaf[label]}}</div>
                            <span class="remove" @click="skuitem[leafs].splice(index, 1)">x</span>
                        </div>
                    </el-popover>
                    <div v-if="skuitem[leafs].length < leamax" class="sku_item" style="padding:0px">
                        <el-input v-model="leafsstr[skuitem[skuid]]" placeholder="多个产品属性以空格隔开" suffix-icon="el-icon-plus" @keyup.native.enter="addLeaf(skuitem[skuid])" @blur="addLeaf(skuitem[skuid])" />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="skulist_.length < skumax" class="spec_title">
            <el-button placeholder="多个产品属性以空格隔开" type='info' @click="addSkus">添加规格项目</el-button>
        </div>
    </div>
</template>
<script>
export default {
    name:'skus-list',
    model: {
        prop: 'skulist',
        event: 'change'
    },
    props: {
        skuid:{type:String, default:'skuid'},
        leaid:{type:String, default:'leaid'},
        label:{type:String, default:'label'},
        leafs:{type:String, default:'leafs'},
        skumax:{type:Number, default:3},
        leamax:{type:Number, default:10},
        skulist:{type:String, default:''},
    },
    data(){
        return {
            skulist_:[],
            leafsstr:{}
        }
    },
    mounted() {
        this.skulist_ = this.skulist?JSON.parse( this.skulist ):[];
    },
    methods: {
        addSkus(){
            let sku = {};
            sku[this.skuid] = this.skulist_.length + 1;
            sku[this.label] = "";
            sku[this.leafs] = [];
            this.$set(this.skulist_,this.skulist_.length,sku);
        },
        addLeaf(skuid){
            let leafs = (this.leafsstr[skuid] || '').trim();
            if( leafs ){
                leafs = leafs.split(/\s+/).filter(value => !this.skulist_[skuid-1][this.leafs].some(option => option[this.label] === value)) // 过滤掉 已存在的 value
                for( let key in leafs){
                    let skuleaf = {};
                    skuleaf[this.label] = leafs[key];
                    skuleaf[this.leaid] = this.skulist_[skuid-1][this.leafs].length + 1;
                    this.$set(this.skulist_[skuid-1][this.leafs],this.skulist_[skuid-1][this.leafs].length,skuleaf);
                }
            }
            this.leafsstr[skuid] = '';
        }
    },
    watch:{
        skulist:{
            deep:true,
            handler(vals){
                this.skulist_ = this.skulist?JSON.parse( this.skulist ):[];
            }
        },
        skulist_:{
            deep:true,
            handler(vals){
                this.$bus.emit('el-goodsku-skulist',vals),this.$emit('change', JSON.stringify(vals));
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.sku_container{
    margin: 10px;
    margin-bottom: 30px;
    font-size: 12px;
    color: #666;
    padding: 10px;
    border: 1px solid #e5e5e5;
    background: #fff;
    .el-input{
        width:auto;
    }
    .remove{
        display: none;
        position: absolute;
        z-index: 2;
        width: 18px;
        height: 18px;
        font-size: 14px;
        line-height: 16px;
        color: #fff;
        text-align: center;
        cursor: pointer;
        background: rgba(0,0,0,.3);
        border-radius: 50%;
    }
    .sku_group{
        margin-bottom: 10px;
        padding-bottom: 10px;
        border: 1px solid #e5e5e5;
        .spec_title{
            position: relative;
            padding: 7px 10px;
            background-color: #f8f8f8;
            line-height: 16px;
            font-weight: 400;

            &:hover{
                .remove{
                    top: 16px;
                    right: 10px;
                    display: block;
                }
            }
        }
        .group_container{
            padding: 0px 10px 0;
            .sku_item{
                background-color: #f8f8f8;
                padding: 10px;
                display: inline-block;
                margin-top: 10px;
                margin-right: 10px;
                vertical-align: middle;
                text-align: center;
                position: relative;
                border-radius: 2px;
                cursor: pointer;

                &:hover{
                    .remove{
                        top: 0px;
                        right: 0px;
                        display: block;
                    }
                }
                .label{
                    display: block;
                    min-width: 74px;
                    margin: 0 auto;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            
        }
    }
}
</style>